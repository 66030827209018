<template>
  <main>
    <HowItWorks />
    <Action />
  </main>
</template>

<script>
// @ is an alias to /src

import HowItWorks from '@/modules/marketing/MarketingHowWorks.vue';
import Action from '@/modules/marketing/MarketingAction.vue';

export default {
  name: 'how-it-works',
  components: { HowItWorks, Action },
  layout: 'default',
  metaInfo: {
    title: 'how-it-works',
  },
};
</script>
